<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <v-row no-gutters class="pt-2">
          <v-col cols="5">
            <v-autocomplete
              v-model="processId"
              outlined
              dense
              :items="processes"
              :loading="isLoadingProcesses"
              :search-input.sync="searchProcesses"
              hide-no-data
              item-text="name"
              item-value="id"
              class="pl-2"
              clearable
              :label="$lang.labels.process"
              :placeholder="$lang.actions.startTyping"
              prepend-inner-icon="mdi-cloud-search-outline"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.name }}
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="2" class="pl-2">
            <v-datetime-picker
              v-model="dateFrom"
              :label="$lang.labels.from"
              no-title
              :text-field-props="{ outlined: true, dense: true }"
            >
              <template v-slot:dateIcon="{}">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon="{}">
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>

          <v-col cols="2" class="pl-2">
            <v-datetime-picker
              v-model="dateTill"
              :label="$lang.labels.to"
              no-title
              :text-field-props="{ outlined: true, dense: true }"
            >
              <template v-slot:dateIcon="{}">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon="{}">
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>

          <!-- Right-aligned refresh button -->
          <v-col cols="2" class="ml-auto d-flex justify-end">
            <v-btn
              text
              light
              color="primary"
              class="ml-2"
              @click="sendQuery()"
            >
              <v-icon dense small>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="statusType"
              class="pl-2"
              :items="formattedStatuses"
              :label="$lang.labels.status"
              outlined
              dense
              clearable
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="eventId"
              :search-input.sync="searchEventId"
              hide-selected
              class="pl-2"
              outlined
              dense
              clearable
              :items="formattedEvents"
              hide-no-data
              :label="items.items && items.items.length > 0 ? $lang.labels.event : $lang.labels.selectProcessFirst"
              :placeholder="items.items && items.items.length > 0 ? $lang.actions.startTyping : $lang.labels.selectProcessFirst"
              :disabled="items.items.length === 0"
              prepend-inner-icon="mdi-format-list-bulleted"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="stepType"
              class="pl-2"
              :items="formattedStepTypes"
              :label="$lang.labels.stepTypes"
              outlined
              dense
              clearable
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="stepId"
              :search-input.sync="searchStepId"
              hide-selected
              class="pl-2"
              outlined
              dense
              clearable
              :items="formattedSteps"
              hide-no-data
              :label="items.items && items.items.length > 0 ? $lang.labels.step : $lang.labels.selectProcessFirst"
              :placeholder="items.items && items.items.length > 0 ? $lang.actions.startTyping : $lang.labels.selectProcessFirst"
              :disabled="items.items.length === 0"
              prepend-inner-icon="mdi-format-list-bulleted"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.eventId="{ item }">
        <div class="d-inline-flex">
          <v-btn
            text
            small
            color="primary"
            @click="showEventDetails(item.eventId)"
          >{{ item.eventId }}</v-btn>
          <v-btn
            text
            small
            color="primary"
            class="ml-1"
            @click="searchEventId = String(item.eventId); eventId = String(item.eventId)"
          >{{ $lang.labels.filter }}</v-btn>
        </div>
      </template>

      <template v-slot:item.finishedOn="{ item }">
        <div>{{ item.finishedOn | formatDateTimePrecise }}</div>
      </template>

      <template v-slot:item.processId="{ item }">
        <div class="d-inline-flex">
          <v-btn
            text
            small
            color="primary"
            @click="goToProcess(item)"
          >{{ item.processId }}</v-btn>
          <v-btn
            text
            small
            color="primary"
            class="ml-1"
            @click="processId = String(item.processId); loadPreFill()"
          >{{ $lang.labels.filter }}</v-btn>
        </div>
      </template>

      <template v-slot:item.stepType="{ item }">
        <div v-if="['JDBC', 'MONGODB'].includes(item.stepType)">{{
          $lang.status[item.stepType]
        }}{{ item.isExistDebugLog ? ': ' : '' }}<span
          class="clickable-simple underline-simple color-primary"
          style="z-index: 1000"
          @click="fetchStepDebugLog(item.id)"
        >{{
          item.isExistDebugLog ? $lang.labels.debugLog : ''
        }}</span>
        </div>
        <div v-else>{{ $lang.status[item.stepType] }}</div>
      </template>

      <template v-slot:item.status="{ item }">
        <div>{{ $lang.status[item.status] }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex">
          <v-btn color="primary" class="mx-1 button-default-width" @click="openLogCache(item.id)">{{ $lang.labels.showCache }}</v-btn>
          <v-btn color="primary" class="mx-1" :disabled="!item.isExistException" @click="fetchStepStackTrace(item)">{{ $lang.labels.est }}</v-btn>
          <v-btn color="info" class="mx-1" @click="goToProcess(item)">{{ $lang.labels.debug }}</v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog
      v-if="showDetails"
      v-model="showDetails"
      max-width="50%"
      style="min-height: 80vh; max-height: 80vh"
    >
      <log-cache
        :data="selectedData"
        :cache-size="cacheSize"
        @closeDialog="selectedData = null; showDetails = false"
        @downloadCache="downloadCache()"
      />
    </v-dialog>
    <v-dialog
      v-if="showSelectedEst"
      v-model="showSelectedEst"
      max-width="71%"
      style="min-height: 80vh; max-height: 80vh"
    >
      <log-est :data="selectedEst" @closeDialog="selectedEst = null; showSelectedEst = false"></log-est>
    </v-dialog>
    <v-dialog
      v-if="showDebugLog"
      v-model="showDebugLog"
      max-width="71%"
      style="min-height: 80vh; max-height: 80vh"
    >
      <log-debug-modal :debug-log-data="debugLogData" @closeDialog="debugLogData = null; showDebugLog = false"/>
    </v-dialog>
    <v-dialog v-if="showDetailsEvent" v-model="showDetailsEvent" max-width="71%" style="min-height: 80vh; max-height: 80vh">
      <log-cache-event-modal :event-id="String(eventIdForModal)" :data="selectedEventData" @closeDialog="eventIdForModal = ''; selectedEventData = null; showDetailsEvent = false"></log-cache-event-modal>
    </v-dialog>
    <v-snackbar
      v-model="snackShow"
      :color="snackColor"
      content-class="text-center"
      top
    >
      <span class="color-accent-text">{{ snackbarText }}</span>
    </v-snackbar>
    <v-dialog v-if="cacheLoading" v-model="cacheLoading" max-width="30%">
      <cache-loading
        :title="$lang.labels.showCache"
        :action-text="$lang.hints.dataTooLArge"
        :loading="cacheInnerLoading"
        @submit="downloadCache()"
        @closeDialog="cacheDownload = null; cacheLoading = false; cacheInnerLoading = false"
      ></cache-loading>
    </v-dialog>
  </div>
</template>

<script>
import {
  getProcessesUsingGET as getProcesses,
  getProcessByIdUsingGET as getProcess, getEventByIdUsingGET as getEvent,
  getProcessStepLogCacheUsingGET as getProcessStepLogCache,
  getProcessStepLogDebugLogUsingGET as getDebugLog,
  getProcessExceptionUsingGET as getException
} from '@/utils/api'
import LogCache from './LogCache'
import LogEst from './LogEst'
import LogCacheEventModal from '../../components/ui/modals/LogCacheEventModal'
import LogDebugModal from '../../components/ui/modals/LogDebugModal'
import CacheLoading from '../../components/ui/modals/LoadingModal'
import { mapState, mapActions } from 'vuex'
import { bus } from '@/main'
import { definitions } from '@/utils/definitions'
import { debounce } from 'lodash'
import { MAX_KB_CACHE_TO_DISPLAY } from '@/utils/constants'

export default {
  components: {
    LogCache,
    LogCacheEventModal,
    LogEst,
    CacheLoading,
    LogDebugModal
  },
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    tableKey: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      showDebugLog: false,
      debugLogData: null,
      snackbarText: '',
      snackShow: false,
      snackColor: 'success',
      showDetails: false,
      showDetailsEvent: false,
      selectedEventData: null,
      selectedEst: null,
      showSelectedEst: false,
      isLoadingProcesses: false,
      showTypeSelector: false,
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.instanceId,
          align: 'start',
          sortable: true,
          value: 'instanceId'
        },
        { text: this.$lang.labels.finishedOn, value: 'finishedOn', sortable: true },
        { text: this.$lang.labels.processId, value: 'processId', sortable: true },
        { text: this.$lang.labels.eventId, value: 'eventId', sortable: true },
        { text: this.$lang.labels.stepType, value: 'stepType', sortable: true },
        { text: this.$lang.labels.stepId, value: 'stepId', sortable: true },
        { text: this.$lang.labels.status, value: 'status', sortable: true },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      processes: [],
      searchProcesses: '',
      dateToday: new Date(),
      dateFrom: new Date(),
      dateTill: new Date(Date.now() + ( 3600 * 1000 * 24)),
      processId: '',
      eventId: '',
      stepId: '',
      lock: true,
      selectedData: null,
      searchEventId: null,
      searchStepId: null,
      stepTypes: definitions.StepStepProperties.properties.type.enum.sort(),
      stepType: '',
      cacheLoading: false,
      cacheInnerLoading: false,
      cacheDownload: null,
      cacheSize: null,
      eventIdForModal: '',
      statuses: ['SUCCESSFUL', 'FAILED'],
      statusType: ''
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userSettings']),
    formattedStepTypes () {
      return this.stepTypes.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedEvents () {
      return this.items && this.items.items && this.items.items.length > 0 ? this.items.items.map((x) => String(x.eventId)) : []
    },
    formattedSteps () {
      return this.items && this.items.items && this.items.items.length > 0 ? this.items.items.map((x) => String(x.stepId)) : []
    },
    formattedStatuses () {
      return this.statuses.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    computedDateFromFormatted () {
      return this.$options.filters.formatDateTime(this.dateFrom)
    },
    computedDateTillFormatted () {
      return this.$options.filters.formatDateTime(this.dateTill)
    }
  },
  watch: {
    searchProcesses: {
      handler: debounce( function (val) {
        if (val && val.length > 1) {
          this.searchProcessesFunction(val)
        }
      }, 500)
    },
    searchEventId: {
      handler(val) {
        if (!val) this.eventId = null
      }
    },
    searchStepId: {
      handler(val) {
        if (!val) this.stepId = null
      }
    },
    options: {
      handler () {
        if (!this.lock) {
          this.sendQuery(false)
          this.savePreFill()
        }
      },
      deep: true
    },
    processId: {
      handler () {
        if (!this.lock) {
          this.eventId = ''
          this.sendQuery()
        }
      }
    },
    dateFrom: {
      handler () {
        if (!this.lock) {
          this.sendQuery()
        }
      }
    },
    dateTill: {
      handler () {
        if (!this.lock) {
          this.sendQuery()
        }
      }
    },
    eventId: {
      handler () {
        if (!this.lock) {
          this.sendQuery()
        }
      }
    },
    stepId: {
      handler () {
        if (!this.lock) {
          this.sendQuery()
        }
      }
    },
    stepType: {
      handler () {
        if (!this.lock) {
          this.sendQuery()
        }
      }
    },
    statusType: {
      handler () {
        if (!this.lock) {
          this.sendQuery()
        }
      }
    },
    items: {
      handler (val) {
        if (val && val.items && val.items.length === 0 && this.eventId && !this.formattedEvents.includes(this.eventId)) {
          this.eventId = ''
          this.sendQuery()
        }
      }
    },
    tableKey: {
      handler () {
        if (this.tableKey === 0 && !this.lock) {
          setTimeout(() => {
            this.lock = false
            this.sendQuery()
          }, 50)
        }
      },
      immediate: true
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.sendQuery()
    })
  },
  created() {

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id', sortable: true })
    }

    this.dateFrom.setHours(0,0,0,0)
    this.dateTill.setHours(23,59,59,999)
    if (this.$route && this.$route.query && this.$route.query.dateFrom  && this.$route.query.dateTill) {
      this.lock = true
      this.eventId = this.$route.query.eventId
      this.stepId = this.$route.query.stepId ? this.$route.query.stepId : ''
      this.statusType = this.$route.query.statusType ? this.$route.query.statusType : ''
      this.dateFrom = new Date(this.$route.query.dateFrom)
      this.dateFrom.setHours(0,0,0,0)
      this.dateTill = new Date(this.$route.query.dateTill)
      this.dateTill.setHours(23,59,59,999)
      this.processId = this.$route.query.processId
      this.loadPreFill()
    } else {
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (preFill && preFill.stepLogList) {
          this.lock = true
          this.eventId = preFill.stepLogList.eventId
          this.stepId = preFill.stepLogList.stepId
          this.dateFrom = new Date(preFill.stepLogList.dateFrom)
          this.dateTill = new Date(preFill.stepLogList.dateTill)
          this.processId = preFill.stepLogList.processId
          this.stepType = preFill.stepLogList.stepType
          this.statusType = preFill.stepLogList.statusType
          this.options = preFill.stepLogList.options

          this.loadPreFill()
        } else {
          setTimeout(() => {
            this.lock = false
            this.sendQuery()
          }, 50)
        }
      } else {
        setTimeout(() => {
          this.lock = false
          this.sendQuery()
        }, 50)
      }
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    showSnack(text, color = 'success') {
      this.snackbarText = text
      this.snackColor = color
      this.snackShow = true
    },
    download(content, fileName) {
      const file = new Blob([content],
        {
          type: 'application/json;charset=UTF-8'
        })

      const reader = new FileReader()

      reader.onload = function()
      {
        const popup = window.open()

        const link = document.createElement('a')

        link.setAttribute('href', reader.result)
        link.setAttribute('download', fileName)
        popup.document.body.appendChild(link)
        link.click()
      }
      reader.readAsDataURL(file)
    },
    downloadCache() {
      if (this.cacheDownload) {
        this.cacheLoading = false
        this.cacheInnerLoading = false
        this.download(this.cacheDownload, 'Cache.json')
      }
    },
    openLogCache(id) {
      this.cacheLoading = true
      this.cacheInnerLoading = true
      this.cacheDownload = null
      getProcessStepLogCache({ id })
        .then((res) => {
          if (res && res.data && res.data.data && res.data.data.cache) {
            const size = new TextEncoder().encode(JSON.stringify(res.data.data.cache)).length
            const kiloBytes = size / 1024

            this.cacheSize = Math.floor(kiloBytes)

            if (kiloBytes < MAX_KB_CACHE_TO_DISPLAY) {
              this.selectedData = res.data.data.cache
            } else {
              this.cacheDownload = JSON.stringify(res.data.data.cache)
            }

            this.cacheLoading = false
            this.cacheInnerLoading = false
            this.showDetails = true
          } else {
            this.cacheLoading = false
            this.cacheInnerLoading = false
            this.cacheDownload = null
            this.showSnack(this.$lang.errors.noCache, 'warning')
            setTimeout(() => this.success = '', 5000)
          }
        })
        .catch((error) => {
          this.addSnackbar({
            message: error,
            timeout: 5000,
            color: 'error'
          })
          console.log(error)
        })
    },
    goToProcess(item) {
      const routeData = this.$router.resolve({
        name: 'processEdit',
        params: { id: item.processId },
        query: { eventId: item.eventId }
      })

      window.open(routeData.href, '_blank')
    },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.stepLogList) preFill.stepLogList = {
          dateFrom: '',
          dateTill: '',
          processId: '',
          eventId: '',
          stepId: '',
          stepType: '',
          statusType: '',
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.stepLogList.dateFrom = this.dateFrom
        preFill.stepLogList.dateTill = this.dateTill
        preFill.stepLogList.processId = this.processId
        preFill.stepLogList.eventId = this.eventId
        preFill.stepLogList.stepId = this.stepId
        preFill.stepLogList.stepType = this.stepType
        preFill.stepLogList.statusType = this.statusType
        preFill.stepLogList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    loadPreFill() {
      if (this.processId) {
        this.isLoadingProcesses = true
        getProcess({
          id : this.processId
        })
          .then((res) => {
            this.processId = ''
            this.processes = [res.data.data]
            this.isLoadingProcesses = false
            this.searchProcesses = res.data.data.name
            this.processId = res.data.data.id
            setTimeout(() => {
              this.lock = false
              this.savePreFill()
              this.sendQuery()
            }, 50)
          })
          .catch((err) => {
            this.isLoadingProcesses = false
            this.addSnackbar({
              message: err,
              timeout: 5000,
              color: 'error'
            })          })
      } else {
        setTimeout(() => {
          this.lock = false
          this.sendQuery()
        }, 50)
      }
    },
    showEventDetails(id) {
      getEvent({
        id
      })
        .then((res) => {
          this.eventIdForModal = id
          this.selectedEventData = res.data.data
          this.showDetailsEvent = true
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    sendQuery(resetPage = true) {
      if (this.dateFrom && this.dateTill && !this.lock) {
        this.$emit('fetchLogs', { options: this.options, dateFrom: this.dateFrom, dateTill: this.dateTill, processId: this.processId, eventId: this.eventId, stepId: this.stepId, stepType: this.stepType, statusType: this.statusType, resetPage })
        // this.savePreFill()
        if (resetPage) this.options.page = 1
      }
    },
    searchProcessesFunction(val = '') {
      this.isLoadingProcesses = true
      getProcesses({
        name: val || ''
      })
        .then((res) => {
          this.processes = res.data.data.items
          this.isLoadingProcesses = false
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    //TODO DRY ProcessDetails
    async fetchStepDebugLog(id) {
      const res = await getDebugLog({ id })
      const { debugLog } = res.data.data

      this.debugLogData = debugLog
      this.showDebugLog = true
    },
    async fetchStepStackTrace(item) {
      const res = await getException({ id: item.id })
      const { message, stackTrace, name } = res.data.data

      this.selectedEst = {
        trace: stackTrace,
        message: message,
        exceptionName: name
      }
      this.showSelectedEst = true
    }
  }
}
</script>
<style lang="scss" scoped>
.clickable {
  cursor: pointer;
  text-decoration: underline;
  color: var(--v-primary-base);
}
</style>
